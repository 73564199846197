import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "regenerating-communal-commerce",
      "style": {
        "position": "relative"
      }
    }}>{`Regenerating Communal Commerce`}<a parentName="h1" {...{
        "href": "#regenerating-communal-commerce",
        "aria-label": "regenerating communal commerce permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Celo’s mission is to build a financial system that creates the conditions for prosperity for everyone. In this special holiday edition of the tokenomics guild, we will be asking ourselves how Celo's tokenomics help it bring crypto to the global south and other emerging markets and realise such lofty ambitions.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://medium.com/celoorg/an-introductory-guide-to-celo-b185c62d3067"
        }}>{`An overview of Celo`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.celo.org/celo-codebase/protocol/stability"
        }}>{`More in-depth readings on Celo’s stability mechanism`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=ItREmpQNORI"
        }}>{`A recent interview from Marek on mobile-first Web3`}</a></li>
      <li parentName="ol">{`Another `}<a parentName="li" {...{
          "href": "https://www.nansen.ai//research/celo-toward-blockchain-mainstream-adoption"
        }}>{`good intro from Nansen`}</a>{` whose main addition to this conversation is the comparison in usage between mobile-first apps on Celo (like Ube) and not-mobile first apps from Ethereum that can also be used on Celo (Sushi). The mobile first apps do much better.`}</li>
    </ol>
    <h2 {...{
      "id": "recording",
      "style": {
        "position": "relative"
      }
    }}>{`Recording`}<a parentName="h2" {...{
        "href": "#recording",
        "aria-label": "recording permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Video src="https://www.youtube-nocookie.com/embed/xe_jcD7ndn4" mdxType="Video" />
    <p>{`We invited Sep Kumvar and Xochitl Cazador from Celo back for another fireside in KB5, which is also archived below:`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/p1lXhTzlCVk" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      